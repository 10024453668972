import React, { useState, useEffect } from "react";
// import InputRange from "react-input-range";
import {
  Button,
  Card,
  CardHeader,
  CardText,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Row,
  Col,
  Input,
} from "reactstrap";

import Slider, { createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";

export function Calculator() {
  const [iniFinance, inYears, inInterest] = [23390, 5, 899];

  const [financeValue, setfinanceValue] = useState(iniFinance);
  const [tradeinValue, settradeinValue] = useState(0);
  const [downPayment, setdownPayment] = useState(0);
  const [amountValue, setAmountValue] = useState(iniFinance);
  const [yearsValue, setYearsValue] = useState(inYears);
  const [interestValue, setInterestValue] = useState(inInterest);

  const SliderWithTooltip = createSliderWithTooltip(Slider);

  let theApr = 0.05;

  const calculateAllRepayment = () => {
    let currAPR = interestValue / 100 / 100;
    // theApr = currAPR
    const decimalFormat = currAPR + 1;
    const totalOwed = decimalFormat * financeValue;
    const monthlyRepayment = totalOwed / (yearsValue * 12);
    const biWeeklyRepayment = totalOwed / (yearsValue * 26);
    const weeklyRepayment = totalOwed / (yearsValue * 52);

    return (
      <CardFooter>
        <Row>
          <Col xs="4">
            <div className="card-stats justify-content-center">
            <span className="value-highlight">
              {Math.round(weeklyRepayment)}
            </span>
            <br />
              <small> / Weekly</small>
            </div>
          </Col>
          <Col xs="4">
            <div className="card-stats justify-content-center">
            <span className="value-highlight">
              {Math.round(biWeeklyRepayment)}
              </span>  
              <br />
              <small> / Bi-Weekly</small>
            </div>
          </Col>
          <Col xs="4">
            <div className="card-stats justify-content-center">
            <span className="value-highlight">
              {Math.round(monthlyRepayment)}
              </span>
             <br />
              <small> / Monthly</small>
            </div>
          </Col>
        </Row>
      </CardFooter>
    );
  };

  useEffect(() => {
    setfinanceValue(amountValue - (downPayment + tradeinValue));
  }, [amountValue, tradeinValue, downPayment]);

  const handleDownChange = async (value) => {
    setdownPayment(value);
    // handleFinanceValue()
  };

  const handleTradeChange = async (value) => {
    // console.log("trade",value)
    settradeinValue(value);
    // console.log("trade",tradeinValue)
    // setfinanceValue(amountValue - (downPayment + value))
    // handleFinanceValue()
  };

  const handleAmountChange = async (value) => {
    setAmountValue(value);
    // handleFinanceValue()
  };

  const onDownChange = (e) => {
    setdownPayment(+e.target.value);
    // handleFinanceValue()
  };

  const onTradeChange = (e) => {
    settradeinValue(+e.target.value);
    // handleFinanceValue()
  };
  const onAmountTextChange = (e) => {
    setAmountValue(+e.target.value);
    // handleFinanceValue()
  };

  const handleYearChange = (value) => {
    setYearsValue(value);
  };

  const handleInterestChange = (value) => {
    setInterestValue(value);
  };

  // let { amount, years, interest } = 0;
  let amount = amountValue;
  let years = yearsValue;
  let interest = interestValue / 100;
  return (
    <div>
      <Row>
        <Col className="text-center" md="12">
          <Card className="card-raised page-carousel">
            <CardBody>
              <h5>
                {" "}
                <Row>
                  <Col xs="7">Amount $</Col>
                  <Col xs="5" className="text-right">
                    <Input
                      type="number"
                      className="text-right"
                      value={amount}
                      onChange={onAmountTextChange}
                    />
                  </Col>
                  <Col xs="12">
                    <SliderWithTooltip
                      value={amountValue}
                      onChange={handleAmountChange}
                      min={5000}
                      max={90000}
                      step={1000}
                      railStyle={{ backgroundColor: "gray", height: 2 }}
                      trackStyle={{ backgroundColor: "blue", height: 2 }}
                    />
                  </Col>
                </Row>
              </h5>

              <h5>
                {" "}
                <Row>
                  <Col xs="7">- Trade $</Col>
                  <Col xs="5" className="text-right">
                    <Input
                      type="number"
                      className="text-right"
                      value={tradeinValue}
                      onChange={onTradeChange}
                    />
                  </Col>
                  <Col xs="12">
                    <SliderWithTooltip
                      value={tradeinValue}
                      onChange={handleTradeChange}
                      min={1000}
                      max={10000}
                      step={250}
                      railStyle={{ backgroundColor: "gray", height: 2 }}
                      trackStyle={{ backgroundColor: "blue", height: 2 }}
                    />
                  </Col>
                </Row>
              </h5>

              <h5>
                {" "}
                <Row>
                  <Col xs="7">- Down $</Col>
                  <Col xs="5" className="text-right">
                    <Input
                      type="number"
                      className="text-right"
                      value={downPayment}
                      onChange={onDownChange}
                    />
                  </Col>
                  <Col xs="12">
                    <SliderWithTooltip
                      value={downPayment}
                      onChange={handleDownChange}
                      min={500}
                      max={5000}
                      step={100}
                      railStyle={{ backgroundColor: "gray", height: 2 }}
                      trackStyle={{ backgroundColor: "blue", height: 2 }}
                    />
                  </Col>
                </Row>
              </h5>

              <h5>
                <Row>
                  <Col xs="7">Total Finance $</Col>
                  <Col xs="5" className="text-right">
                    {financeValue}{" "}
                  </Col>
                </Row>
              </h5>

              <h5>
                Over {years} year{years > 1 && "s"}
                <SliderWithTooltip
                  value={yearsValue}
                  onChange={handleYearChange}
                  min={1}
                  max={8}
                  railStyle={{ backgroundColor: "gray", height: 2 }}
                  trackStyle={{ backgroundColor: "blue", height: 2 }}
                />
              </h5>
              <h5>
                {interest}% Interest
                <SliderWithTooltip
                  value={interestValue}
                  onChange={handleInterestChange}
                  min={199}
                  max={2500}
                  railStyle={{ backgroundColor: "gray", height: 2 }}
                  trackStyle={{ backgroundColor: "blue", height: 2 }}
                />
              </h5>
            </CardBody>
            <hr />
            {calculateAllRepayment()}
          </Card>
        </Col>
      </Row>

      <small>Indicative values shown</small>
    </div>
  );
}

export function LongCalculator() {
  const [iniFinance, inYears, inInterest] = [23390, 5, 899];

  const [financeValue, setfinanceValue] = useState(iniFinance);
  const [tradeinValue, settradeinValue] = useState(0);
  const [downPayment, setdownPayment] = useState(0);
  const [amountValue, setAmountValue] = useState(iniFinance);
  const [yearsValue, setYearsValue] = useState(inYears);
  const [interestValue, setInterestValue] = useState(inInterest);

  const SliderWithTooltip = createSliderWithTooltip(Slider);

  const radioforOutput2 = () => {
    let currAPR = interestValue / 100 / 100;
    // theApr = currAPR
    const decimalFormat = currAPR + 1;
    const totalOwed = decimalFormat * financeValue;
    const monthlyRepayment = totalOwed / (yearsValue * 12);
    const biWeeklyRepayment = totalOwed / (yearsValue * 26);
    const weeklyRepayment = totalOwed / (yearsValue * 52);

    return (
      <Row>
        <Col sm="4">
          <Card body>
            <CardText>Weekly</CardText>
            <CardText>
              <p className="value-highlight">${Math.round(weeklyRepayment)}</p>
            </CardText>
          </Card>
        </Col>
        <Col sm="4">
          <Card body>
            <CardText>Bi Weekly</CardText>
            <CardText>
              <p className="value-highlight">
                ${Math.round(biWeeklyRepayment)}
              </p>
            </CardText>
          </Card>
        </Col>
        <Col sm="4">
          <Card body>
            <CardText>Monthly</CardText>
            <CardText>
              <p className="value-highlight">${Math.round(monthlyRepayment)}</p>
            </CardText>
          </Card>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    setfinanceValue(amountValue - (downPayment + tradeinValue));
  }, [amountValue, tradeinValue, downPayment]);

  const handleDownChange = async (value) => {
    setdownPayment(value);
    // handleFinanceValue()
  };

  const handleTradeChange = async (value) => {
    // console.log("trade",value)
    settradeinValue(value);
    // console.log("trade",tradeinValue)
    // setfinanceValue(amountValue - (downPayment + value))
    // handleFinanceValue()
  };

  const handleAmountChange = async (value) => {
    setAmountValue(value);
    // handleFinanceValue()
  };

  const onDownChange = (e) => {
    setdownPayment(+e.target.value);
    // handleFinanceValue()
  };

  const onTradeChange = (e) => {
    settradeinValue(+e.target.value);
    // handleFinanceValue()
  };
  const onAmountTextChange = (e) => {
    setAmountValue(+e.target.value);
    // handleFinanceValue()
  };

  const handleYearChange = (value) => {
    setYearsValue(value);
  };

  const handleInterestChange = (value) => {
    setInterestValue(value);
  };

  // let { amount, years, interest } = 0;
  let amount = amountValue;
  let years = yearsValue;
  let interest = interestValue / 100;
  return (
    <div>
      <Row>
        <Col className="text-center" md="12">
          <Card className="card-contributions">
            <CardBody>
              <h5>
                {" "}
                <Row>
                  <Col xs="7">Amount $</Col>
                  <Col xs="5" className="text-right">
                    <Input
                      type="number"
                      className="text-right"
                      value={amount}
                      onChange={onAmountTextChange}
                    />
                  </Col>
                  <Col xs="12">
                    <SliderWithTooltip
                      value={amountValue}
                      onChange={handleAmountChange}
                      min={5000}
                      max={90000}
                      step={1000}
                      railStyle={{ backgroundColor: "gray", height: 2 }}
                      trackStyle={{ backgroundColor: "blue", height: 2 }}
                    />
                  </Col>
                </Row>
              </h5>

              <h5>
                {" "}
                <Row>
                  <Col xs="7">- Trade $</Col>
                  <Col xs="5" className="text-right">
                    <Input
                      type="number"
                      className="text-right"
                      value={tradeinValue}
                      onChange={onTradeChange}
                    />
                  </Col>
                  <Col xs="12">
                    <SliderWithTooltip
                      value={tradeinValue}
                      onChange={handleTradeChange}
                      min={1000}
                      max={10000}
                      step={250}
                      railStyle={{ backgroundColor: "gray", height: 2 }}
                      trackStyle={{ backgroundColor: "blue", height: 2 }}
                    />
                  </Col>
                </Row>
              </h5>

              <h5>
                {" "}
                <Row>
                  <Col xs="7">- Down $</Col>
                  <Col xs="5" className="text-right">
                    <Input
                      type="number"
                      className="text-right"
                      value={downPayment}
                      onChange={onDownChange}
                    />
                  </Col>
                  <Col xs="12">
                    <SliderWithTooltip
                      value={downPayment}
                      onChange={handleDownChange}
                      min={500}
                      max={5000}
                      step={100}
                      railStyle={{ backgroundColor: "gray", height: 2 }}
                      trackStyle={{ backgroundColor: "blue", height: 2 }}
                    />
                  </Col>
                </Row>
              </h5>

              <h5>
                <Row>
                  <Col xs="7">Total Finance $</Col>
                  <Col xs="5" className="text-right">
                    {financeValue}{" "}
                  </Col>
                </Row>
              </h5>

              <h5>
                Over {years} year{years > 1 && "s"}
                <SliderWithTooltip
                  value={yearsValue}
                  onChange={handleYearChange}
                  min={1}
                  max={8}
                  railStyle={{ backgroundColor: "gray", height: 2 }}
                  trackStyle={{ backgroundColor: "blue", height: 2 }}
                />
              </h5>
              <h5>
                {interest}% Interest
                <SliderWithTooltip
                  value={interestValue}
                  onChange={handleInterestChange}
                  min={199}
                  max={2500}
                  railStyle={{ backgroundColor: "gray", height: 2 }}
                  trackStyle={{ backgroundColor: "blue", height: 2 }}
                />
              </h5>
            </CardBody>

            {/* {radioforOutput2()} */}

            <small>Indicative values shown</small>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Calculator;
