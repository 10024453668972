import { lazy } from 'react';
// import { Route } from "react-router-dom";



// import Application from "Application/index";
// import Bryden from "Home/index";
// import Sell from "Sell"
// import TestDrive from "TestDrive"
// import Application from "Application/index";
// import Inventory from "Inventory/index";
// import LandingPage from "LandingPage/index";
// import DisplayPage from "Inventory/DisplayPage";

// import Step1 from "Application/Steps/Step1";
// import Step2 from "Application/Steps/Step2";
// import Step3 from "Application/Steps/Step3";
// import Step4 from "Application/Steps/Step4";
// import Step5 from "Application/Steps/Step5";


import Error404 from "Pages/Error404.js";
import Error422 from "Pages/Error422.js";
import Error500 from "Pages/Error500.js";

import Team from "About/Team"
import About from "About/index"
import WhyChooseUs from "About/WhyChooseUs"

import CreditCenter from "Finance/CreditCenter"
import FinancialService from "Finance/FinancialServices"

import Contact from "Contact/index"
import CustomerFeeback from "About/CustomerFeeback"
import TipsAdvice from "Finance/index"

// pages

const Bryden = lazy(() => import("Home/index"));
const Sell = lazy(() => import('Sell'));
const TestDrive = lazy(() => import("TestDrive"))
const Application = lazy(() => import("Application/index"));
const Inventory = lazy(() => import("InventoryBasicList/index"))
const InventoryList = lazy(() => import("InventoryList/index"))
const LandingPage = lazy(() => import("LandingPage/index"))
const DisplayPage = lazy(() => import("DisplayBasic/index"))

const Terms = lazy(() => import("Home/TermsnConditions"))
const Privacy = lazy(() => import("Home/PrivacyPolicy"))
export const routes = [
  {
    path: "/credit-application",
    name: "Credit Application",
    component: Application,
  },

  {
    path: "/page/:id?",
    name: "landing page",
    component: LandingPage,
  },
  {
    path: "/inventory",
    name: "Inventory",
    component: Inventory,
  },
  {
    path: "/inventory-list",
    name: "Inventory",
    component: InventoryList,
  },
  {
    path: "/sell",
    name: "Sell Car",
    component: Sell,
  },
  {
    path: "/test-drive",
    name: "Book a Test Drive",
    component: TestDrive,
  },
  {
    path: "/error-404",
    name: "Error 404",
    component: Error404,
  },
  {
    path: "/error-422",
    name: "Error 422",
    component: Error422,
  },
  {
    path: "/error-500",
    name: "Error 500",
    component: Error500,
  },
  {
    path: "/vehicle/:id/:slug?",
    name: "VDP",
    component: DisplayPage,
  },
  {
    path: "/customer-feeback",
    name: "CustomerFeeback",
    component: CustomerFeeback,
  },
  {
    path: "/tips-advice",
    name: "Tips and Advice",
    component: TipsAdvice,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/credit-center",
    name: "Credit Center",
    component: CreditCenter,
  },
  {
    path: "/financial-service",
    name: "Financial Service",
    component: FinancialService,
  },

  {
    path: "/about-us",
    name: "About Us",
    component: About,
  },
  {
    path: "/why-choose-us",
    name: "Why Choose Us",
    component: WhyChooseUs,
  },
  // 
  {
    path: "/team",
    name: "Team",
    component: Team,
  },
  {
    path: "/",
    name: "Home",
    component: Bryden,
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: Privacy,
  },
  {
    path: "/terms-and-conditions",
    name: "Terms And conditions",
    component: Terms,
  },
];

export default routes;
