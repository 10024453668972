import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

import AboutUsHeader from "./AboutUsHeader.js";
import ToolCards from "Home/ToolCards.js";

import config from "_config"

function AboutUs() {
  document.documentElement.classList.remove("nav-open");
  
  return (
    <>

      <AboutUsHeader />
      <div className="main">
        <div className="section">
          <Container>
            <h3 className="title-uppercase">About Us</h3>
            <p>
            {config.company} is a family-owned 
            and operated Dealership that can give our customers 
            that personal touch that you can't find elsewhere. 
            Located on Sackville drive in Lower Sackville, we 
            might be the friendliest car dealership you've ever 
            encountered.
            </p>
            <p>
            We only offer the best quality, pre-owned cars, trucks, 
            and SUVs at competitive prices. We have licensed Mechanics 
            who perform a motor vehicle inspection of all pre-owned 
            vehicles before they're sold and delivered to you.
            </p>
            <h3 className="title-uppercase">
              We
              <i className="fa fa-heart heart mr-3 ml-1" />
              what we do.
            </h3>
            <p>
            Learn first-hand why many of our sales come are repeat 
            customers and referrals to their friends and family. 
            We look forward to winning your trust and business by 
            putting you in your next pre-owned vehicle!
            </p>
            <h2 className="text-center creators">Sales Team</h2>
            <Row>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardBody>
                    <div>
                      
                        <img
                          alt="..."
                          src={require("assets/images/team/pexels-photo-1197132.jpeg")}
                        />
                        <CardTitle tag="h4">Eric Thomson</CardTitle>
                      
                    </div>
                    <p className="card-description text-center">
                      A group becomes a team when each member is sure enough of
                      himself and his contribution to praise the skill of the
                      others. No one can whistle a symphony. 
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                       className="btn-rnd "
                       color="success"
                      href="tel:9028654495"
                      
                    >
                      Click to Call
                    </Button>
                    
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardBody>
                    <div>
                     
                        <img
                          alt="..."
                          src={require("assets/images/team/pexels-photo-937481.jpeg")}
                        />
                        <CardTitle tag="h4">Sophia West</CardTitle>
                      
                    </div>
                    <p className="card-description text-center">
                      The strength of the team is each individual member. The
                      strength of each member is the team. If you can laugh
                      together, you can work together, silence isn’t golden,
                      it’s deadly.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                  <Button
                       className="btn-rnd "
                       color="success"
                      href="tel:9028654495"
                      
                    >
                      Click to Call
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardBody>
                    <div>
                      
                        <img
                          alt="..."
                          src={require("assets/images/team/adult-beautiful-blonde-blur-324658.jpg")}
                        />
                        <CardTitle tag="h4">Lucas Andrew</CardTitle>
                      
                    </div>
                    <p className="card-description text-center">
                      Great teams do not hold back with one another. They are
                      unafraid to air their dirty laundry. They admit their
                      mistakes, their weaknesses and their concerns 
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                  <Button
                      className="btn-rnd "
                      color="success"
                      href="tel:9028654495"
                      
                    >
                      Click to Call
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
           
          </Container>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
