import React from "react";
import ReactDOM from "react-dom";


// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

import App from './App';

ReactDOM.render(
  <App/>,
  document.getElementById("root")
);