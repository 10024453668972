import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components
import config from "_config"
function AboutUsHeader() {
  return (
    <>
      <div
        className="page-header page-header-small"
        style={{
          backgroundImage:
            "url(" + require("assets/images/team/team-pic.jpg") + ")"
        }}
      >
        <div className="filter filter-danger" />
        <div className="content-center">
          <Container>
            <h1>
              
              We are {config.short_co}.
            </h1>
           
          </Container>
        </div>
      </div>
    </>
  );
}

export default AboutUsHeader;
