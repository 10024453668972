import React from "react";
// react plugin used to create google maps
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

import LeadSource from "utilities/LeadSource";
import NumberFormat from 'react-number-format';
import {
  CREATE_CONTACT_US,
} from "constants/actions";
import config from "_config";
const email_validation = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


function ContactUs() {
  const dispatch = useDispatch();
  const { register, handleSubmit, watch, errors, control, reset } = useForm();
  const [leadSource, SetLeadSource ] = React.useState(LeadSource())
  const [visible, setVisible] = React.useState(false);

  const [userIp, setUserIp] = React.useState();
    React.useEffect(() => {
      if (!userIp) {
        async function fetchData() {
          const res = await fetch("https://api.ipify.org?format=json");
          res
            .json()
            .then((res) => {
              setUserIp(res.ip);
            })
            .catch((err) => console.log(err));
        }
        fetchData();
      }
    })


    const onSubmit = (data, e) => {


      dispatch({
        type: CREATE_CONTACT_US,
        payload: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.emailiid,
          phone: data.phone,
          description: data.description,
          web_lead_stage:config.web_lead_stage,
          web_lead_type:config.web_lead_type_contact,
          Web_lead_source:config.web_lead_source,
          campaign_code:leadSource.campaign_code,
          deviceType:leadSource.deviceType,
          gclid:leadSource.gclid,
          isBrowser:leadSource.isBrowser,
          isMobile:leadSource.isMobile,
          browser: leadSource.browser,
          lead_source:leadSource.lead_source,
          mssclid:leadSource.mssclid,
          osName:leadSource.osName,
          osVersion:leadSource.osVersion,
          pixelid:leadSource.pixelid,
          userIp:userIp,
          utm_campaign:leadSource.utm_campaign,
          utm_medium:leadSource.utm_medium,
          utm_source:leadSource.utm_source,
        },
      });
      setVisible(true)
        e.target.reset(); // HTML standard reset() function will only reset inputs' value
    }

    // console.log(errors)
  return (
    <>

      <div className="section section-contactus cd-section" id="contact-us">
        {/* ********* CONTACT US 1 ********* */}
        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage:
              "url(" + require("assets/images/other-images/soroush-karimi.jpg") + ")"
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <Card className="card-contact no-transition">
                  <CardTitle className="text-center" tag="h3">
                    Contact Us
                  </CardTitle>
                  <Row>
                    <Col className="ml-auto" md="5">
                      <CardBody>
                        <div className="info info-horizontal">
                          <div className="icon icon-info">
                            <i className="nc-icon nc-pin-3" />
                          </div>
                          <div className="description">
                            <h4 className="info-title">
                              Office Timings
                            </h4>
                            <p> Monday: 8:00 am to 7:00 pm </p>
                            <p> Tuesday: 8:00 am to 7:00 pm </p>
                            <p> Wednesday: 8:00 am to 7:00 pm </p>
                            <p> Thursday: 8:00 am to 7:00 pm </p>
                            <p> Friday: 8:00 am to 7:00 pm </p>
                            <p> Saturday: 8:00 am to 7:00 pm </p>
                            <p> Sunday: Closed </p>
                          </div>
                        </div>
                        {/* <div className="info info-horizontal">
                          <div className="icon icon-danger">
                            <i className="nc-icon nc-badge" />
                          </div>
                          <div className="description">
                            <h4 className="info-title">Give us a ring</h4>
                            <p>
                             
                            </p>
                          </div>
                        </div> */}
                      </CardBody>
                    </Col>
                    <Col className="mr-auto" md="5">
                    <Alert color="success" isOpen={visible} toggle={() => setVisible(!visible)}>
     
     <p>Thanks for contacting us!</p>
    </Alert>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <FormGroup className="label-floating">
                                <label className="control-label">
                                  First name <span className="icon-danger">*</span>
                                </label>
                                <Input
                                  name="first_name"
                                  placeholder="First Name"
                                  type="text"
                                  innerRef={register({
                                    required: "Please enter First Name",
                                  })}
                                />
                                <p className="text-danger small">
                    {errors.first_name && (
                      <span>{errors.first_name.message}</span>
                    )}
                  </p>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="label-floating">
                                <label className="control-label">
                                  Last name <span className="icon-danger">*</span>
                                </label>
                                <Input
                                  name="last_name"
                                  placeholder="Last Name"
                                  type="text"
                                  innerRef={register({
                                    required: "Please enter Last Name",
                                  })}
                                />
                                <p className="text-danger small">
                    {errors.last_name && (
                      <span>{errors.last_name.message}</span>
                    )}
                  </p>
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup>
              <p>
                Phone <span className="icon-danger">*</span>
              </p>

              {/* <Controller
                  as={<NumberFormat format="+1 (###) ###-####" 
                  mask="_" />}
                  control={control}
                  
                  className="border-input"
                  name="phone"
                  placeholder="enter the Phone Number"
                  type="text"
                  innerRef={register({
                    required: "Please enter phone number",
                    minlength: 10,
                  })}
                /> */}
             <Input
                className="border-input"
                name="phone"
                placeholder="enter the Phone Number"
                type="text"
                innerRef={register({
                  required: "Please enter phone number",
                  minlength: 10,
                })}
              />
              <p className="text-danger small">
                {errors.phone && <span>{errors.phone.message}</span>}
              </p>
            </FormGroup>

                          <FormGroup className="label-floating">
                            <label className="control-label">
                              Email address <span className="icon-danger">*</span>
                            </label>
                            <Input
                              name="emailiid"
                              placeholder="Email"
                              type="email"
                              innerRef={register({
                                required: "Email is required",
                                pattern: {
                                  value: email_validation,
                                  message: "invalid email address",
                                },
                                maxlength: 20,
                              })}
                             
                            />
                            <p className="text-danger small">
                              {errors.emailiid && <span>{errors.emailiid.message}</span>}
                            </p>
                          </FormGroup>
                          <FormGroup className="label-floating">
                            <label className="control-label">
                              Your message <span className="icon-danger">*</span>
                            </label>
                            <Input
                              id="description"
                              name="description"
                              placeholder="Message"
                              type="textarea"
                              rows="6"
                              innerRef={register({
                                required: "Please enter Message",
                              })}
                            />
                            <p className="text-danger small">
                {errors.description && (
                  <span>{errors.description.message}</span>
                )}
              </p>
                          </FormGroup>
              
                          <FormGroup check>
              <Label check>
                <Controller
                  as={<Input type="checkbox" />}
                  name="agree"
                  value={true}
                  control={control}
                  defaultValue={false}
                  innerRef={register({
                    required: "Please Check this box",
                  })}
                />
                I agree to terms and conditions{" "}
                <span className="form-check-sign" />
              </Label>
              <p className="text-danger small">
                {errors.agree && <span>{errors.agree.message}</span>}
              </p>
            </FormGroup>
                          <Row>
                            <Col md="6">
                              
                            </Col>
                            <Col md="6">
                              <Button
                                className="pull-right"
                                color="primary"
                                type="submit"
                              >
                                Send Message
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Form>
                    
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END CONTACT US 1 ********* */}
       
      </div>
    </>
  );
}

export default ContactUs;
