import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import createSagaMiddleware from 'redux-saga';
import { logger } from 'redux-logger';
import rootReducer from "./reducers";

import { rootSaga } from './sagas/index'


// this where we initialize state
const initialState = {};

const sagaMiddleware = createSagaMiddleware()
const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(sagaMiddleware, logger)
    ),
);

// const store = createStore(
//   rootReducer,
//   initialState,
//   (
//     applyMiddleware(sagaMiddleware)
//   ),
// );
sagaMiddleware.run(rootSaga)

export default store;