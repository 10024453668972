import React from "react";

// reactstrap components
import { Container, Button, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

import config from "_config"
const Footer = () => {
  return (
    <>

     
      <footer className="footer footer-black footer-big">
        <Container>
          <Row>
            <Col className="text-center ml-auto mr-auto" 
            md="2" sm="3" xs="12">
               <img 
            src={require('assets/images/logo/demo-autosales-logo-white.svg')} 
            alt={config.company} width="200" />
              <div className="social-area">
                <Button
                  className="btn-just-icon btn-round mr-1"
                  color="facebook"
                >
                  <i className="fa fa-facebook" />
                </Button>
                <Button
                  className="btn-just-icon btn-round mr-1"
                  color="twitter"
                >
                  <i className="fa fa-twitter" />
                </Button>
                <Button className="btn-just-icon btn-round" 
                color="google">
                  <i className="fa fa-google-plus" />
                </Button>
              </div>
            </Col>
            <Col className="ml-auto mr-auto" md="9" sm="9" xs="12">
              <Row>
                <Col md="3" sm="3" xs="6">
                  <div className="links">
                    <ul className="uppercase-links stacked-links">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/inventory">Inventory</Link>
                      </li>
                      <li>
                        <Link to="/financial-service">Finance</Link>
                      </li>
                      <li>
                        <Link to="/customer-feeback">Testimonial</Link>
                      </li>
                      <li>
                        <Link to="/sell">Cash for Car</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md="3" sm="3" xs="6">
                  <div className="links">
                    <ul className="uppercase-links stacked-links">
                      <li>
                      <Link to="/contact">Contact Us</Link>
                       
                      </li>
                      <li>
                      <Link to="/team">We're Hiring</Link>
                        
                      </li>
                      <li>
                      <Link to="/about-us">About Us</Link>
                        
                      </li>
                    </ul>
                  </div>
                </Col>

                <Col md="4" sm="4" xs="6">
                  <div className="links">
                    <ul className="stacked-links">
                      <li>
                        <h6>
                        {config.address}
                          <br />
                          {config.city}
                          <br />
                          {config.province}
                        </h6>
                      </li>
                      <li>
                        <h6>{config.pincode}</h6>
                      </li>
                      <li>
                        <h6>{config.phone}</h6>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <hr />
              <div className="copyright">
                <div className="pull-left">
                  © {new Date().getFullYear()} {config.company}  
                </div>
                <div className="links pull-right">
                  <ul>
                  <li className="mr-1">
                      <a href="https://www.preautoco.com" target="_blank" rel="noopener noreferrer">
                        Powered by PreAutoCo.com
                      </a>
                    </li>
                    |{" "}
                    <li className="mr-1">
                      <a href="https://dangraham.ca/" target="_blank" rel="noopener noreferrer">
                        Design by Dan Graham
                      </a>
                    </li>
                    |{" "}
                    <li className="mr-1">
                    <Link to="/terms-and-conditions">Terms and Conditions</Link>
                     
                    </li>
                    |{" "}
                    <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    
                   
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
