import React from "react";
// reactstrap components

// core components

import SectionBlog from "./SectionBlog.js";
import "./style.css"

function Sections() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    // document.body.classList.add("section-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
    // this function is used to make the right bullets list
    // (the bellow <nav id="cd-vertical-nav">)
    // active when changeing the section on scroll
    const updateView = () => {
      var contentSections = document.getElementsByClassName("cd-section");
      var navigationItems = document
        .getElementById("cd-vertical-nav")
        .getElementsByTagName("a");

      for (let i = 0; i < contentSections.length; i++) {
        var activeSection =
          parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
        if (
          contentSections[i].offsetTop - window.innerHeight / 2 <
            window.pageYOffset &&
          contentSections[i].offsetTop +
            contentSections[i].scrollHeight -
            window.innerHeight / 2 >
            window.pageYOffset
        ) {
        //   navigationItems[activeSection].classList.add("is-selected");
        } else {
        //   navigationItems[activeSection].classList.remove("is-selected");
        }
      }
    };

    window.addEventListener("scroll", updateView);
    return function cleanup() {
      document.body.classList.remove("section-page");
      window.removeEventListener("scroll", updateView);
    };
  });
  return (
    <>


      <SectionBlog />

      <nav id="cd-vertical-nav">
        <ul>
          <li>
            <a
              data-number="1"
              href="#no-money-down"
              onClick={e => {
                e.preventDefault();
                document.getElementById("no-money-down")
                .scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest"
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">No Money Down</span>
            </a>
          </li>
          <li>
            <a
              data-number="2"
              href="#bankruptcy"
              onClick={e => {
                e.preventDefault();
                document.getElementById("bankruptcy").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest"
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Bankruptcy</span>
            </a>
          </li>
          <li>
            <a
              data-number="3"
              href="#bad-credit"
              onClick={e => {
                e.preventDefault();
                document.getElementById("bad-credit").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest"
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Bad Credit</span>
            </a>
          </li>
          <li>
            <a
              data-number="4"
              href="#best-loan"
              onClick={e => {
                e.preventDefault();
                document.getElementById("best-loan").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest"
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Best Loan</span>
            </a>
          </li>
          <li>
            <a
              data-number="5"
              href="#0-down"
              onClick={e => {
                e.preventDefault();
                document.getElementById("0-down").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest"
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">0 Down Loan</span>
            </a>
          </li>
          <li>
            <a
              data-number="6"
              href="#used-car-loan"
              onClick={e => {
                e.preventDefault();
                document.getElementById("used-car-loan").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest"
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Used Car Loan</span>
            </a>
          </li>
          
          <li>
            <a
              data-number="7"
              href="#qualify-car-loan"
              onClick={e => {
                e.preventDefault();
                document.getElementById("qualify-car-loan").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest"
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Qualify for Loan</span>
            </a>
          </li>
          
        </ul>
      </nav>
     
    </>
  );
}

export default Sections;
