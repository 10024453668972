import { ADD_WEBLEAD, CLEAR_ALL } from "constants/actions";

const initialState = {
    webleads: [],
};

export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_WEBLEAD:
            return {
                ...state,
                weblead: [...state.weblead, action.payload]
            };
       
        case CLEAR_ALL:
            return {
                ...state,
                weblead: []
            };
        default:
            return state;
    }
}

