import React from 'react';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  deviceType,
  osName,
  osVersion,
  deviceDetect,
} from "react-device-detect";


const LeadSource = () => {

  const search = window.location.search;
  const params = new URLSearchParams(search);


  const campaign_code = params.get("campaign-code");
  const gclid = params.get("gclid");
  const pixelid = params.get("pixelid");
  const mssclid = params.get("mssclid");
  const lead_source = params.get("lead-source");
  const utm_source = params.get("utm-source");
  const utm_medium = params.get("utm-medium");
  const utm_campaign = params.get("utm-campaign");



  const deviceDetected = deviceDetect();

  // getMyIp()

  const lead_source_params = {
        campaign_code,
        gclid,
        pixelid,
        mssclid,
        lead_source,
        utm_source,
        utm_medium,
        utm_campaign,
        isMobile,
        isBrowser,
        browser: deviceDetected.browserName,
        deviceType,
        osVersion,
        osName,
        deviceDetected,
      }

  return lead_source_params;
};

export default LeadSource;
