import { GET_ACTIVE_PAGE, SG_GET_ACTIVE_PAGE, LOAD_ACTIVE_PAGE, 
    UPDATE_ACTIVE_PAGE, SG_UPDATE_ACTIVE_PAGE, ADD_ACTIVE_PAGE, 
    CLEAR_ALL } from "constants/actions";


const initialState = {
    activePage: 0,
    pageActivePage: 1
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_ACTIVE_PAGE:
            return {  ...state,
                activePage: action.payload,
                pageActivePage: action.payload + 1};
        case GET_ACTIVE_PAGE:
            return {  ...state};
        case UPDATE_ACTIVE_PAGE:
            return {  ...state,
                activePage: action.payload,
                pageActivePage: action.payload + 1};
        case CLEAR_ALL:
            return {
                ...state,
                activePage: [],
                pageActivePage: []
            };
        default:
            return state;
    }
}