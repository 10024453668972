import React, { Suspense, lazy } from 'react';

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider} from "react-redux";
import store from "./store";

import AuthLayout from "Layouts/Auth.jsx";
import ScrollToTop from "utilities/ScrollToTop"

const hist = createBrowserHistory();

const App = () => {
    
    return (
      <Provider store={store}>
      <Router history={hist} > 
      <Suspense fallback={<div>Loading...</div>}>
        <ScrollToTop />
      <Switch>
       
        <Route path="/" render={props => 
        <AuthLayout {...props} />} />

      </Switch>
      </Suspense>
      
    </Router>
    </Provider>
    )
  }

export default App