import React from "react";
// react plugin used to create google maps
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
// reactstrap components
import Rating from 'react-rating';

import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

import LeadSource from "utilities/LeadSource";
import NumberFormat from 'react-number-format';
import {
  CREATE_WEB_COMMENT,
} from "constants/actions";
import config from "_config";

const email_validation = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


const GiveFeedback = (props) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, watch, errors, control, reset } = useForm();
  const [leadSource, SetLeadSource ] = React.useState(LeadSource())
  const [visible, setVisible] = React.useState(false);
  const [rating, setRating] = React.useState(0);


  const [userIp, setUserIp] = React.useState();
    React.useEffect(() => {
      if (!userIp) {
        async function fetchData() {
          const res = await fetch("https://api.ipify.org?format=json");
          res
            .json()
            .then((res) => {
              setUserIp(res.ip);
            })
            .catch((err) => console.log(err));
        }
        fetchData();
      }
    })

    const ratingOnclick = (e) => {
      // console.log("on click", e)
      // setRating(e)
    }

    const ratingOnHover = (e) => {
      // console.log("on Hover", e)
      // setRating(e)
    }

    const ratingOnChange = (e) => {
      console.log("on Change", e)
      setRating(e)
    }

    React.useEffect(() => {
      if (!rating) {
        register(
          { name: "rating" },
          { required: "Please give us a rating" }
        );
      } else {
        register(
          { name: "rating" },
          { required: false }
        );
      }

      // scheduled_for
    }, [register, rating]);

    const onSubmit = (data, e) => {
      dispatch({
        type: CREATE_WEB_COMMENT,
        payload: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.emailiid,
          phone: data.phone,
          description: data.description,
          rating: rating,
          web_lead_stage:config.web_lead_stage,
          web_lead_type:config.web_lead_type_feedback,
          Web_lead_source:config.web_lead_source,
          campaign_code:leadSource.campaign_code,
          deviceType:leadSource.deviceType,
          gclid:leadSource.gclid,
          isBrowser:leadSource.isBrowser,
          isMobile:leadSource.isMobile,
          lead_source:leadSource.lead_source,
          mssclid:leadSource.mssclid,
          osName:leadSource.osName,
          osVersion:leadSource.osVersion,
          pixelid:leadSource.pixelid,
          userIp:userIp,
          utm_campaign:leadSource.utm_campaign,
          utm_medium:leadSource.utm_medium,
          utm_source:leadSource.utm_source,
        },
      });
      setVisible(true)
        e.target.reset(); // HTML standard reset() function will only reset inputs' value
    }

    console.log(errors)

  return (
    <>
      <Container>
        <h2 className="title text-center">{props.heading}</h2>

         <Alert color="success" isOpen={visible} toggle={() => setVisible(!visible)}>
     
     <p>Thank you giving your feedback!</p>
    </Alert>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <FormGroup className="label-floating">
                                <label className="control-label">
                                  First name <span className="icon-danger">*</span>
                                </label>
                                <Input
                                  name="first_name"
                                  placeholder="First Name"
                                  type="text"
                                  innerRef={register({
                                    required: "Please enter First Name",
                                  })}
                                />
                                <p className="text-danger small">
                    {errors.first_name && (
                      <span>{errors.first_name.message}</span>
                    )}
                  </p>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="label-floating">
                                <label className="control-label">
                                  Last name <span className="icon-danger">*</span>
                                </label>
                                <Input
                                  name="last_name"
                                  placeholder="Last Name"
                                  type="text"
                                  innerRef={register({
                                    required: "Please enter Last Name",
                                  })}
                                />
                                <p className="text-danger small">
                    {errors.last_name && (
                      <span>{errors.last_name.message}</span>
                    )}
                  </p>
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup>
              <p>
                Phone <span className="icon-danger">*</span>
              </p>

              {/* <Controller
                  as={<NumberFormat format="+1 (###) ###-####" 
                  mask="_" />}
                  control={control}
                  
                  className="border-input"
                  name="phone_no"
                  placeholder="enter the Phone Number"
                  type="text"
                  innerRef={register({
                    required: "Please enter phone number",
                    minlength: 10,
                  })}
                />
                 <p className="text-danger small">
                {errors.phone_no && <span>{errors.phone_no.message}</span>}
              </p> */}
             <Input
                className="border-input"
                name="phone"
                placeholder="enter the Phone Number"
                type="text"
                innerRef={register({
                  required: "Please enter phone number",
                  minlength: 10,
                })}
              />
              <p className="text-danger small">
                {errors.phone && <span>{errors.phone.message}</span>}
              </p>
            </FormGroup>

                          <FormGroup className="label-floating">
                            <label className="control-label">
                              Email address <span className="icon-danger">*</span>
                            </label>
                            <Input
                              name="emailiid"
                              placeholder="Email"
                              type="email"
                              innerRef={register({
                                required: "Email is required",
                                pattern: {
                                  value: email_validation,
                                  message: "invalid email address",
                                },
                                maxlength: 20,
                              })}
                             
                            />
                            <p className="text-danger small">
                              {errors.emailiid && <span>{errors.emailiid.message}</span>}
                            </p>
                          </FormGroup>
                          <FormGroup className="label-floating">
                            <label className="control-label">
                              Your message <span className="icon-danger">*</span>
                            </label>
                            <Input
                              id="description"
                              name="description"
                              placeholder="Message"
                              type="textarea"
                              rows="6"
                              innerRef={register({
                                required: "Please enter Message",
                              })}
                            />
                            <p className="text-danger small">
                {errors.description && (
                  <span>{errors.description.message}</span>
                )}
              </p>
                          </FormGroup>
              <FormGroup>
              <label className="control-label">
                              Rate this interaction <span className="icon-danger">*</span>
                            </label>

                       
              <Rating 
              onChange={ratingOnChange} 
              onClick={ratingOnclick} 
              onHover={ratingOnHover}
              initialRating={rating}
              name="rating"
              emptySymbol="fa fa-star-o fa-1.5x"
              fullSymbol="fa fa-star fa-2x"
              fractions={10} 
              
                            />
{!rating?
<p className="text-danger small">
                {errors.rating && <span>{errors.rating.message}</span>}
              </p> :
              ""
}

              </FormGroup>
                          <FormGroup check>
              <Label check>
                <Controller
                  as={<Input type="checkbox" />}
                  name="agree"
                  value={true}
                  control={control}
                  defaultValue={false}
                  innerRef={register({
                    required: "Please Check this box",
                  })}
                />
                I agree to terms and conditions{" "}
                <span className="form-check-sign" />
              </Label>
              <p className="text-danger small">
                {errors.agree && <span>{errors.agree.message}</span>}
              </p>
            </FormGroup>
                          <Row>
                            <Col md="6">
                              
                            </Col>
                            <Col md="6">
                              <Button
                                className="pull-right"
                                color="primary"
                                type="submit"
                              >
                                Send Message
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Form>
      </Container>
    </>
  );
};

export default GiveFeedback;
